import { ResponseLogin } from 'src/app/Models/output.model/ResponseLogin';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

export class common {
    public LoginResult: ResponseLogin;
    public PortalResult: any;
    public cookieService: CookieService;
    public CheckLogin() {
        this.LoginResult = new ResponseLogin();
        this.LoginResult = this.getUserinfo();
        if (this.LoginResult == null) {
            this.router.navigate(['/']);
        }
    }
    public CheckLoginPortal() {
        this.PortalResult=null;
        this.PortalResult = this.getPortalInfo();
        let userPortal= this.getUserPortalInfo()
        if (this.PortalResult == null && userPortal == null) {
            this.router.navigate(['/login-portal']);
        }
    }
    constructor(private router: Router) {}
    public getUserinfo() {
        return JSON.parse(localStorage.getItem('UserInfo'));
    }
    public getPortalInfo() {
        this.PortalResult = JSON.parse(localStorage.getItem('PortalInfo'))
        // if(this.PortalResult==null)
        // {
        //     this.PortalResult = JSON.parse(localStorage.getItem('UserPortalInfo'))
        // }
        return this.PortalResult;
    }

    public getUserPortalInfo() {
        this.PortalResult = JSON.parse(localStorage.getItem('PortalInfo'))
        // this.PortalResult = JSON.parse(localStorage.getItem('UserPortalInfo'))
        return this.PortalResult;
    }
    
    login() {
        this.router.navigate(['/admin/dashboard']); 
    }

    logout() {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/']);
    }

    redirectToLoginSystem() {
        localStorage.removeItem("UserInfo");
        this.router.navigate(["/login"]);
      }

      redirectToLoginPortalSystem() {
        localStorage.removeItem("UserInfo");
        this.router.navigate(["/login-portal"]);
      }

      redirectToUrl(url : string) {
        localStorage.removeItem("UserInfo");
        this.router.navigate([url]);
      }
}