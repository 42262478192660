import { Component, OnInit, ViewChild } from "@angular/core";
import { SurveyService } from "src/app/Utils/service/survey.service";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { HocKyDangKyService } from "src/app/Utils/service/hoc-ky-dang-ky.service";
import { LoaiDanhGiaService } from "src/app/Utils/service/loai-danh-gia.service";
import { SeasonService } from "src/app/Utils/service/season.service";
import { common } from "src/app/app.common";
import { PhanPhoiTinChiService } from "src/app/Utils/service/phan-phoi-tin-chi.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CoreService } from "src/app/Utils/service/core.service";
import { SearchObjectService } from "src/app/Utils/service/search-object.service";

@Component({
  selector: "app-phan-phoi-tin-chi-sinh-vien-giang-vien",
  templateUrl: "./phan-phoi-tin-chi-sinh-vien-giang-vien.component.html",
  styleUrls: ["./phan-phoi-tin-chi-sinh-vien-giang-vien.component.scss"],
})
export class PhanPhoiTinChiSinhVienGiangVienComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings;
  dtTrigger = new Subject();
  trangThaiGui: any;
  tableData: any = [];
  tableDataFilter: any = [];
  tableDetailData: any = {
    Dot_danh_gia: '',
    Ngay_bat_dau: '',
    Ngay_ket_thuc: '',
    ListSingVien: []
  };
  hocKyDangKy: any;
  dotDanhGia: any;
  phieuDanhGia: any;
  phieuDanhGiaFilter: any;
  loaiDanhGia: any;
  Token: any;
  UserInfo: any;
  ChonCount: any = 0;
  closeResult: string;
  Fillter = new FormGroup({
    ID_hoc_ky_dang_ky: new FormControl(0),
    ID_phieu_danh_gia: new FormControl(0),
    ID_loai_danh_gia: new FormControl(0),
    ID_dot_danh_gia: new FormControl(0),
    Text_search: new FormControl(null),
    Trang_thai_gui: new FormControl(0),
    ID_he: new FormControl(0),
    ID_khoa: new FormControl(0),
    Khoa_hoc: new FormControl(0),
    ID_nganh: new FormControl(0),
    ID_chuyen_nganh: new FormControl(0)
  });
  public com: common;

  ListHe: any = [];
  ListKhoa: any = [];
  ListKhoaAll: any = [];
  ListKhoaBase: any = [];
  ListKhoaHoc: any = [];
  ListKhoaHocAll: any = [];
  ListKhoaHocBase: any = [];
  ListNganh: any = [];
  ListChuyenNganh: any = [];
  ListChuyenNganhAll: any = [];
  ListChuyenNganhBase: any = [];

  constructor(
    public router: Router,
    private HocKyDangKyService: HocKyDangKyService,
    public seasonService: SeasonService,
    private loaiDanhGiaService: LoaiDanhGiaService,
    public SurveyService: SurveyService,
    public phanPhoiTinChiService: PhanPhoiTinChiService,
    public searchObjectService: SearchObjectService,
    private coreService: CoreService,
    private toastr: ToastrService,
    public spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) { }

  async ngOnInit() {
    // get UserData
    this.com = new common(this.router);
    this.com.CheckLogin();
    var saveData = this.com.getUserinfo();
    this.UserInfo = saveData.Info.UserName;
    this.Token = saveData.access_token;

    // get data danh mục
    await this.getHocKyDangKy();
    await this.getDotDanhGia();
    this.getLoaiDanhGia();
    this.getPhieuDanhGia();
    this.getLopTinChi();
    this.initTrangThaiGui();
    this.getHe();
    this.getKhoa();
    this.getKhoaHoc();
    this.getNganh();
    this.getChuyenNganh();
  }


  getHocKyDangKy(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.HocKyDangKyService.getAll(this.Token).subscribe((z) => {
        this.hocKyDangKy = z.ListData;
        this.Fillter.patchValue({
          ID_hoc_ky_dang_ky: this.hocKyDangKy.length > 0 ? this.hocKyDangKy[0].Ky_dang_ky : 0
        })
        resolve(); // Gọi resolve() khi hàm hoàn thành
      }, error => {
        reject(error); // Gọi reject() nếu có lỗi xảy ra
      });
    });
  }


  getDotDanhGia(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.seasonService.getListSeason(this.Token).subscribe(res => {
        this.dotDanhGia = res.Data;
        this.Fillter.patchValue({
          ID_dot_danh_gia: this.dotDanhGia.length > 0 ? this.dotDanhGia[0].ID_dot_danh_gia : 0
        })

        resolve(); // Gọi resolve() khi hàm hoàn thành
      }, error => {
        reject(error); // Gọi reject() nếu có lỗi xảy ra
      });
    });
  }

  getLoaiDanhGia() {
    this.loaiDanhGiaService.getAll(this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.loaiDanhGia = z.ListData;
      }
    });
  }

  getPhieuDanhGia() {
    this.SurveyService.getListPhieuDanhGia(this.Token).subscribe((z) => {
      this.phieuDanhGia = z.Data;
      this.phieuDanhGiaFilter = z.Data;


    });
  }
  initTrangThaiGui() {
    this.trangThaiGui = [
      { trangThai: "Đã gửi phiếu", value: 1 },
      { trangThai: "Chưa gửi phiếu", value: 2 }
    ]
  }

  open(content: any, size: string, dt: any) {
    this.tableDetailData.ListSingVien = dt;
  
    const dotDanhGia = this.dotDanhGia.find(d => d.ID_dot_danh_gia == this.Fillter.value.ID_dot_danh_gia);
    
    if (dotDanhGia) {
      this.tableDetailData.Dot_danh_gia = dotDanhGia.Ten_dot;
      this.tableDetailData.Ngay_bat_dau = new Date(dotDanhGia.Ngay_bat_dau).toLocaleDateString('vi-VN');
      this.tableDetailData.Ngay_ket_thuc = new Date(dotDanhGia.Ngay_ket_thuc).toLocaleDateString('vi-VN');
    }
  
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  

  getLopTinChi() {
    var data = this.Fillter.value;

    // if (data.ID_hoc_ky_dang_ky == 0) {
    //   this.toastr.warning("Vui lòng chọn kỳ đăng ký");
    //   return;
    // }
    this.spinner.show();
    this.phanPhoiTinChiService
      .LopTinChi_LoadList(this.Token, data)
      .subscribe((z) => {
        this.spinner.hide();
        this.tableDataFilter = z.Data;
        this.ChonCount = 0;


      });
  }

  checkOne(index) {
    this.tableDataFilter[index].Chon = !this.tableDataFilter[index].Chon;
    this.ChonCount = this.tableDataFilter.filter((obj) => {
      return obj.Chon == true;
    }).length;
  }

  checkAll() {
    var value = false;
    var ChonCount = this.tableDataFilter.filter((obj) => {
      return obj.Chon == true;
    }).length;

    if (ChonCount < this.tableDataFilter.length) {
      value = true;
    }
    for (let index = 0; index < this.tableDataFilter.length; index++) {
      this.tableDataFilter[index].Chon = value;
    }
    this.ChonCount = this.tableDataFilter.filter((obj) => {
      return obj.Chon == true;
    }).length;
  }

  filterPhieuDanhGia() {
    this.phieuDanhGiaFilter = this.phieuDanhGia.filter((obj) => {
      return (
        obj.ID_loai_danh_gia == this.Fillter.value.ID_loai_danh_gia ||
        this.Fillter.value.ID_loai_danh_gia == 0
      );

    });

  }

  private getDismissReason(reason: any): string {
    if (reason == ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason == ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  guiPhieuLopTinChi() {
    if (this.Fillter.value.ID_phieu_danh_gia == 0 || this.Fillter.value.ID_dot_danh_gia == 0 || this.ChonCount == 0) {
      this.toastr.warning("Vui lòng chọn đợt đánh giá, phiếu đánh giá và lớp cần gửi phiếu");
      return;
    }
    //   const listIdLopTc = this.tableDataFilter
    // .filter(item => item.Chon)
    // .map(item => item.ID_lop_tc);
    //   var req= {
    //     Id_lop_tc:listIdLopTc,
    //     ID_phieu_danh_gia:this.Fillter.value.ID_phieu_danh_gia,
    //     ID_dot_danh_gia:this.Fillter.value.ID_dot_danh_gia
    //   }

    var data = {
      ds_lop_tc: [],
      ID_phieu_danh_gia: this.Fillter.value.ID_phieu_danh_gia,
      ID_dot_danh_gia: this.Fillter.value.ID_dot_danh_gia
    }
    for (let i = 0; i < this.tableDataFilter.length; i++) {
      if (this.tableDataFilter[i].Chon == true) {


        var res = {
          ID_lop_tc: this.tableDataFilter[i].ID_lop_tc,
          ID_giao_vien: this.tableDataFilter[i].ID_cb
        }

        data.ds_lop_tc.push(res)
      }

    }
    var r = confirm("Bạn có muốn gửi phiếu đến danh sách lớp tin chỉ đã chọn không?")
    if (!r) {
      return;
    }
    this.spinner.show();
    this.phanPhoiTinChiService
      .GuiPhieuTinChi(this.Token, data)
      .subscribe((z) => {
        this.spinner.hide();
        this.toastr.success(z.Message);
        this.getLopTinChi();
      });

  }

  getHe() {
    this.coreService.GetHe(this.Token).subscribe((z) => {
      this.ListHe = z.Data;
    });
  }

  getKhoa() {
    this.coreService.GetKhoa(this.Token).subscribe((z) => {
      this.ListKhoaBase = z.Data;
      
      const uniqueKhoaMap = new Map();

      this.ListKhoaBase.forEach((item: any) => {
        if (!uniqueKhoaMap.has(item.ID_khoa)) {
          uniqueKhoaMap.set(item.ID_khoa, item);
        }
      });

      this.ListKhoaAll = Array.from(uniqueKhoaMap.values());
      this.ListKhoa = this.ListKhoaAll;
    });
  }

  getKhoaHoc() {
    this.coreService.GetKhoaHoc(this.Token).subscribe((z) => {
      this.ListKhoaHocBase = z.Data;

      const uniqueKhoaHocMap = new Map();

      this.ListKhoaHocBase.forEach((item: any) => {
        if (!uniqueKhoaHocMap.has(item.Khoa_hoc)) {
          uniqueKhoaHocMap.set(item.Khoa_hoc, item);
        }
      });

      this.ListKhoaHocAll = Array.from(uniqueKhoaHocMap.values());
      this.ListKhoaHoc = this.ListKhoaHocAll;
    });
  }

  getNganh() {
    this.coreService.GetNganh(this.Token).subscribe((z) => {
      this.ListNganh = z;
    });
  }

  getChuyenNganh() {
    this.coreService.GetChuyenNganh(this.Token).subscribe((z) => {
      this.ListChuyenNganhBase = z.Data;

      const uniqueChuyenNganhMap = new Map();

      this.ListChuyenNganhBase.forEach((item: any) => {
        if (!uniqueChuyenNganhMap.has(item.ID_chuyen_nganh)) {
          uniqueChuyenNganhMap.set(item.ID_chuyen_nganh, item);
        }
      });

      this.ListChuyenNganhAll = Array.from(uniqueChuyenNganhMap.values());
      this.ListChuyenNganh = this.ListChuyenNganhAll;
    });
  }

  changeKhoa() {
    var ID_he = this.Fillter.value.ID_he ? this.Fillter.value.ID_he : 0;
    var ID_khoa = this.Fillter.value.ID_khoa ? this.Fillter.value.ID_khoa : 0;
    if (ID_he == 0) {
      this.ListKhoa = this.ListKhoaAll;
    } else {
      var listKhoaTemp = this.ListKhoaBase.filter((x: any) => x.ID_he == ID_he);

      this.ListKhoa = listKhoaTemp.reduce((uniqueList: any[], current: any) => {
        if (!uniqueList.some(item => item.ID_khoa == current.ID_khoa)) {
          uniqueList.push(current);
        }
        return uniqueList;
      }, []);

      if (!this.ListKhoa.some(item => item.ID_khoa == ID_khoa) && ID_khoa != 0) {
        this.Fillter.get('ID_khoa').patchValue(0);
      }
    }
  }

  changeKhoaHoc() {
    var ID_he = this.Fillter.value.ID_he ? this.Fillter.value.ID_he : 0;
    var ID_khoa = this.Fillter.value.ID_khoa ? this.Fillter.value.ID_khoa : 0;
    var Khoa_hoc = this.Fillter.value.Khoa_hoc ? this.Fillter.value.Khoa_hoc : 0;
    if (ID_he == 0 && ID_khoa == 0) {
      this.ListKhoaHoc = this.ListKhoaHocAll;
    } else {
      var listKhoaHocTemp: any[] = [];
      if (ID_he != 0 && ID_khoa == 0) {
        listKhoaHocTemp = this.ListKhoaHocBase.filter((x: any) => x.ID_he == ID_he);
      } else if (ID_he == 0 && ID_khoa != 0) {
        listKhoaHocTemp = this.ListKhoaHocBase.filter((x: any) => x.ID_khoa == ID_khoa);
      } else {
        listKhoaHocTemp = this.ListKhoaHocBase.filter((x: any) => x.ID_he == ID_he && x.ID_khoa == ID_khoa);
      }
      this.ListKhoaHoc = listKhoaHocTemp.reduce((uniqueList: any[], current: any) => {
        if (!uniqueList.some(item => item.Khoa_hoc == current.Khoa_hoc)) {
          uniqueList.push(current);
        }
        return uniqueList;
      }, []);
      if (!this.ListKhoaHoc.some(item => item.Khoa_hoc == Khoa_hoc) && Khoa_hoc != 0) {
        this.Fillter.get('Khoa_hoc').patchValue(0);
      }
    }
  }

  changeChuyenNganh() {
    var ID_he = this.Fillter.value.ID_he ? this.Fillter.value.ID_he : 0;
    var ID_khoa = this.Fillter.value.ID_khoa ? this.Fillter.value.ID_khoa : 0;
    var Khoa_hoc = this.Fillter.value.Khoa_hoc ? this.Fillter.value.Khoa_hoc : 0;
    var ID_nganh = this.Fillter.value.ID_nganh ? this.Fillter.value.ID_nganh : 0;
    var ID_chuyen_nganh = this.Fillter.value.ID_chuyen_nganh ? this.Fillter.value.ID_chuyen_nganh : 0;
    if (ID_he == 0 && ID_khoa == 0 && Khoa_hoc == 0 && ID_nganh == 0) {
      this.ListChuyenNganh = this.ListChuyenNganhAll;
    } else {
      var listChuyenNganhTemp = this.ListChuyenNganhBase;
      if (ID_he != 0) {
        listChuyenNganhTemp = listChuyenNganhTemp.filter((x: any) => x.ID_he == ID_he);
      }

      if (ID_khoa != 0) {
        listChuyenNganhTemp = listChuyenNganhTemp.filter((x: any) => x.ID_khoa == ID_khoa);
      }

      if (Khoa_hoc != 0) {
        listChuyenNganhTemp = listChuyenNganhTemp.filter((x: any) => x.Khoa_hoc == Khoa_hoc);
      }

      if (ID_nganh != 0) {
        listChuyenNganhTemp = listChuyenNganhTemp.filter((x: any) => x.ID_nganh == ID_nganh);
      }

      this.ListChuyenNganh = listChuyenNganhTemp.reduce((uniqueList: any[], current: any) => {
        if (!uniqueList.some(item => item.ID_chuyen_nganh == current.ID_chuyen_nganh)) {
          uniqueList.push(current);
        }
        return uniqueList;
      }, []);

      if (!this.ListChuyenNganh.some(item => item.ID_chuyen_nganh == ID_chuyen_nganh) && ID_chuyen_nganh != 0) {
        this.Fillter.get('ID_chuyen_nganh').patchValue(0);
      }
    }
  }

  trackByChuyenNganh(index: number, item: any): number {
    return item.ID_chuyen_nganh;
  }

  trackByKhoaHoc(index: number, item: any): number {
    return item.Khoa_hoc;
  }

  trackByKhoa(index: number, item: any): number {
    return item.ID_khoa;
  }

  deleteMulti(data: any) {
    if(data == undefined || data == null || data.length == 0){
      this.toastr.warning("Không có dữ liệu");
      return;
    }
    else{
      let listID: number[] = data.map(item => item.ID_gui);
      var r = confirm("Bạn có muốn xóa phiếu đã được gửi đến danh sách lớp đã chọn không?")
      if (!r) {
        return;
      }
      this.spinner.show();
      this.searchObjectService.DeleteMulti(listID, this.Token).subscribe((z) => {
        this.spinner.hide();
        this.toastr.success(z.Message);
        this.getLopTinChi();
      });
    }
  }
}
